<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;" class="filter-item" size="small" @keyup.enter="getList" />
        <el-button class="filter-item1" type="primary" size="small" plain @click="getList">搜索</el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:700px" class="styleForm">
            <el-form-item label="采集状态：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-button size="small" plain type="primary" @click="declaration('now')">批量迁移建账（1）</el-button>
        <!-- <el-button size="small" plain type="primary" @click="declaration('old')">批量迁移历史数据（2）</el-button> -->

      </div>
    </div>
    <div class="none-border">
      <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column align="center" type="index" label="编号" min-width="50" />
        <el-table-column align="left" prop="name" label="公司名称" min-width="180" class="gsmc">
          <template #default="scope">
            <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
            <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="迁移状态" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.taskStatus == '0'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>{{$setUpStatusQY(scope.row.taskStatus)}}</p>
                <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusQY(scope.row.taskStatus)}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="提示信息" min-width="120" class="gsmc">
          <template #default="scope">
            <span>{{ scope.row.errLog }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="districtName" label="历史数据迁移状态" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.taskStatusOld == '0'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatusOld)}}</p>
                <p v-if="scope.row.taskStatusOld == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatusOld)}}</p>
                <p v-if="scope.row.taskStatusOld == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusQY(scope.row.taskStatusOld)}}</p>
                <p v-if="scope.row.taskStatusOld == '3'"><i class="iconfont icon-duihao"></i>{{$setUpStatusQY(scope.row.taskStatusOld)}}</p>
                <p v-if="scope.row.taskStatusOld == '4'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusQY(scope.row.taskStatusOld)}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="提示信息" min-width="120" class="gsmc">
          <template #default="scope">
            <span>{{ scope.row.errLogOld }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <!-- <el-button @click="gatherCJ(scope.row)" size="small" type="text">迁移</el-button> -->
              <el-button @click="declareDetail(scope.row)" size="small" type="text">迁移记录</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>

  <declareDetails ref="declareDetails"></declareDetails>
</template>

<script>
import { company1To2List } from "@/api/company"
import { companyMove ,companyOldMove } from "@/api/move"
import declareDetails from "../batch/components/declareDetails.vue";
import { currentAccountPeriod } from '@/utils'
export default {
  name: "batchSendSb",
  components:{
    declareDetails
  },
  data(){
    return {
      activeName:'税款申报',
      formLabelWidth: '120px',
      listQuery: {
        page:1,
        limit:20,
        // taskName:"other-cj-jianzhang",
        period: currentAccountPeriod(),
        name: '',
        taskStatus: [],
        taxNames:[],
      },
      total:0,
      list:[],
      form: {
        name: '金财互联',
        userName:'',
        password:'',
        orgCode:''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '公司代码', trigger: 'blur' },
        ],
      },
      gsMainIds: undefined,
      pageStatus: true,

      cjzt: undefined,
      dialogVisible: false,
      options: [
        {
          value: 0,
          label: '未迁移',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '迁移中',
        },
        {
          value: 3,
          label: '迁移完成',
        },
        {
          value: 4,
          label: '迁移失败',
        },
      ],
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(240)
  },
  methods:{
    getList(){
      company1To2List(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.taskStatus == 1 || res.taskStatus == 2 || res.taskStatusOld == 1 || res.taskStatusOld == 2) {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    //批量迁移
    declaration(val) {
      console.log(this.gsMainIds,'this.gsMainIds')
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      if(val == 'now'){
        this.$prompt('请输入初始账期', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if(value){
            this.gsMainIds.map(v=>{
              v.startAccountPeriod = value
            })
          }
          companyMove(this.gsMainIds).then(res => {
            if(res.data.msg == 'success') {
              this.$message({
                type: 'success',
                message: '任务已发起!'
              });
              this.getList()
            }
          })
        });
      } else {
        this.$confirm('要发起批量迁移任务吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          companyOldMove(this.gsMainIds).then(res => {
            if(res.data.msg == 'success') {
              this.$message({
                type: 'success',
                message: '任务已发起!'
              });
              this.getList()
            }
            // this.loading = false
          })
        })
      }
     
    },
    //记账客户迁移
    gatherCJ(row) {
      // this.loading = true
      this.$confirm('要发起迁移任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        companyMove([{id: row.comId}]).then(res => {
          if(res.data.msg == 'success') {
            this.$message({
              type: 'success',
              message: '任务已发起!'
            });
            this.getList()
          }
          // this.loading = false
        })
      }).catch(() => {
        // this.loading = false
      });
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push({id:info.comId})
      })
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,e.startAccountPeriod,'qianyi')
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>